import httpClient from '@/core/client/http.client';
import {
  CreateResourceFormType,
  DeleteResourceType,
  GetResourcesListType,
  ResourceFormType,
  // ResourcePreviewFormType,
  ResourcesItemType,
  UpdateResourceFormType,
} from './../types.d';
import { TResponseSecondary } from '@/shared/types/promises.types';

const getResources: GetResourcesListType = async (params, signal) => {
  return httpClient.get<TResponseSecondary<ResourcesItemType[]>>(
    '/pdf-resources',
    {
      params,
      signal,
    },
  );
};

const createResource: CreateResourceFormType = async form => {
  return httpClient.post<ResourceFormType, ResourcesItemType>(
    '/pdf-resource',
    form,
  );
};

const updateResource: UpdateResourceFormType = async (id, form) => {
  return httpClient.patch<ResourceFormType, ResourcesItemType>(
    `/pdf-resource/${id}`,
    form,
  );
};

const deleteResource: DeleteResourceType = async id => {
  return httpClient.delete(`/pdf-resource/${id}`);
};

const savePreview = async (form: FormData): Promise<void> => {
  return httpClient.postFile('/pdf-resource/image', form);
};

const deletePreview = async (id: number): Promise<void> => {
  return httpClient.delete(`/pdf-resource/image/${id}`);
};

const methods = {
  getResources,
  updateResource,
  createResource,
  deleteResource,
  savePreview,
  deletePreview,
};

export default methods;
