import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import SearchBlock from '@/shared/components/SearchBlock';
import DebounceInput from '@/shared/components/DebounceInput';
import { GetParamsResourceType, ResourceTypesType } from '../types';
import { TTypeItem } from '@/shared/api/types/types.api.types';

type Props = {
  queries: GetParamsResourceType;
  types: TTypeItem[];
  onSearchResources: (e: string) => void;
  onSelectResourceType: (e: ResourceTypesType[]) => void;
  onSelectedType: (value: TTypeItem[]) => void;
  onSearchTypes: (query: string) => void;
};

const ResourcesFilters = (props: Props) => {
  const {
    queries,
    types,
    onSearchResources,
    onSelectResourceType,
    onSelectedType,
    onSearchTypes,
  } = props;

  const [resourceType, setResourceType] = useState<ResourceTypesType[]>([]);
  const [defaultSelectedResourceType] = useState<TTypeItem[]>(
    [...types].filter(type =>
      queries.resourceType?.split(',').includes(type.value),
    ),
  );

  const handleSelectType = (event: SelectChangeEvent<ResourceTypesType[]>) => {
    const {
      target: { value },
    } = event;
    onSelectResourceType(value as ResourceTypesType[]);
  };

  const handleSearchTypes = (value: string) => {
    onSearchTypes(value);
  };

  useEffect(() => {
    setResourceType([]);

    if (queries.isDay) {
      setResourceType(prev => [...prev, 'isDay']);
    }
    if (queries.isRecommended) {
      setResourceType(prev => [...prev, 'isRecommended']);
    }
    if (queries.isTop) {
      setResourceType(prev => [...prev, 'isTop']);
    }
    if (queries.isTrend) {
      setResourceType(prev => [...prev, 'isTrend']);
    }
  }, [queries]);

  return (
    <>
      <SearchBlock
        value={queries.query || ''}
        placeholder="Search resource by name"
        onInput={onSearchResources}
        sx={{ marginBottom: 4, maxWidth: 400 }}
      />

      <Box
        display={'flex'}
        alignItems={'center'}
        flexWrap={'wrap'}
        mb={3}
        rowGap={1}
        columnGap={1}
      >
        <FormControl
          sx={{
            maxWidth: 200,
            width: '100%',
          }}
        >
          <InputLabel id="demo-multiple-name-label">
            Resource sort list
          </InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            value={resourceType}
            label="Resource sort list"
            multiple={true}
            onChange={handleSelectType}
          >
            <MenuItem value="isDay">Is a day resource</MenuItem>
            <MenuItem value="isRecommended">Is a recommended resource</MenuItem>
            <MenuItem value="isTop">Is a top resource</MenuItem>
            <MenuItem value="isTrend">Is a trend resource</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ width: '100%', maxWidth: 400 }}>
          <Autocomplete
            multiple
            options={types}
            getOptionLabel={option => option.name}
            defaultValue={
              queries.resourceType ? defaultSelectedResourceType : []
            }
            onChange={(_, value) => onSelectedType(value as TTypeItem[])}
            renderInput={params => (
              <DebounceInput
                {...params}
                handleDebounce={handleSearchTypes}
                debounceTimeout={300}
                label="Resource type"
              />
            )}
          />
        </FormControl>
      </Box>
    </>
  );
};

export default ResourcesFilters;
