import { useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { ResourcesItemType, ResourceFormType } from '../types';

import useValidate, { Rules } from '@/shared/hooks/useValidate.hook';
import mappingErrors from '@/shared/helpers/mappingErrors.helper';
import DebounceInput from '@/shared/components/DebounceInput';
import ImageLoader from '@/shared/components/FormImageLoader';

import FormModal from '@/shared/components/Forms/FormModal';
import { CompanyItemType } from '@/shared/api/compnaies/compnaies.api.types';
import { TTypeItem } from '@/shared/api/types/types.api.types';
// import { KeywordItemType } from '@/shared/api/keywords/keywords.api.types';
import { useNotify } from '@/shared/context/notifyContext';
import { useAuthentication } from '@/shared/context/authContext';

import Langueages from '@/shared/constants/lang.const';

type Props = {
  resource?: ResourcesItemType | null;
  loading: boolean;
  companies: CompanyItemType[];
  types: TTypeItem[];
  onCreate: (form: ResourceFormType) => void;
  onUpdate: (form: ResourceFormType) => void;
  onSearchCompanies: (query: string) => void;
  onSearchTypes: (query: string) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement | undefined>) => void;
};

type CompanySelectedValueType = {
  id: number;
  name: string;
  value: string;
};

const ResourceForm = (props: Props) => {
  const {
    resource,
    companies,
    types,
    // keywords,
    onCancel,
    onCreate,
    onUpdate,
    onSearchCompanies,
    onSearchTypes,
    loading,
  } = props;

  const { setNotify } = useNotify();

  const { user } = useAuthentication();

  const [isAdmin] = useState<boolean>(
    user?.role === 'admin' || user?.role === 'Admin',
  );

  const [isSuperAdmin] = useState<boolean>(
    user?.role === 'superadmin' || user?.role === 'Super Admin',
  );

  const [isEdit] = useState<boolean>(!!resource);
  const [pdfFileName, setPdfFileName] = useState<string>('');
  const [selectedCompany, setSelectedCompany] =
    useState<CompanySelectedValueType | null>(
      resource
        ? {
            id: resource.company?.id || 0,
            name: resource.company?.name || '',
            value: resource.company?.id.toString() || '',
          }
        : null,
    );

  const [selectedTypes, setSelectedTypes] = useState<TTypeItem | null>(
    resource
      ? types.find(type => type.id === resource.resource_type_id) || null
      : null,
  );

  const generated_downloads = resource?.downloads_count
    ? Number(resource.downloads_count)
    : null;

  // const num_of_downloads = resource?.num_of_downloads
  //   ? Number(resource.num_of_downloads)
  //   : null;

  // const random_generated_downloads = generated_downloads
  //   ? num_of_downloads
  //     ? generated_downloads + num_of_downloads
  //     : generated_downloads
  //   : num_of_downloads
  //     ? num_of_downloads
  //     : 0;

  const [form, setForm] = useState<ResourceFormType>({
    name: resource?.name || '',
    company_id: resource?.company?.id || null,
    resource_type_id: resource?.resource_type_id || null,
    short_description: resource?.short_description || '',
    file_path: resource?.file_path || '',
    image_path: resource?.image_path || '',
    is_top: resource?.is_top || false,
    is_trend: resource?.is_trend || false,
    is_day: resource?.is_day || false,
    is_recommended: resource?.is_recommended || false,
    doc_language: resource?.doc_language || 'en',
    num_of_pages: resource?.num_of_pages || null,
    reading_time: resource?.reading_time || null,
    random_generated_downloads: generated_downloads,

    resource_update_date: resource?.resource_update_date
      ? dayjs(resource.resource_update_date).format('YYYY-MM-DD')
      : null,
  });

  const [mappedCompanies, setMappedCompanies] = useState<
    CompanySelectedValueType[]
  >([]);

  const rules: Rules<ResourceFormType> = {
    name: {
      required: true,
      maxLength: 250,
    },
    short_description: {
      required: {
        message: 'Resource description is required',
      },
    },

    company_id: {
      required: {
        message: 'Company is required',
      },
    },

    resource_type_id: {
      required: {
        message: 'Resource type is required',
      },
    },

    image_path: {
      required: true,
    },

    file_path: {
      required: true,
    },

    is_top: {
      required: false,
    },

    is_trend: {
      required: false,
    },

    is_day: {
      required: false,
    },

    is_recommended: {
      required: false,
    },

    doc_language: {
      required: false,
    },

    num_of_pages: {
      required: false,
    },

    reading_time: {
      required: false,
    },

    resource_update_date: {
      required: false,
    },

    resource_creation_date: {
      required: false,
    },

    random_generated_downloads: {
      required: false,
    },
  };

  const { dirty, touch, errors } = useValidate(form, rules);
  const [dateError, setDateError] = useState<string>('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading || touch() || dateError) {
      return;
    }

    if (isEdit) {
      onUpdate(form);
    } else {
      onCreate(form);
    }
  };

  const onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (
      name === 'num_of_pages' ||
      name === 'reading_time' ||
      name === 'random_generated_downloads'
    ) {
      setForm(prevForm => ({
        ...prevForm,
        [name]: value === '' ? null : Math.max(0, +value),
      }));
    } else {
      setForm(prevForm => ({
        ...prevForm,
        [name]: value,
      }));
    }

    if (dirty) {
      touch();
    }
  };

  const onSelectedCompany = (company: CompanySelectedValueType | null) => {
    setSelectedCompany(company);

    setForm(prevForm => ({
      ...prevForm,
      company_id: company?.id || null,
    }));

    if (dirty) {
      touch();
    }
  };

  const onSelectedType = (type: TTypeItem | null) => {
    setSelectedTypes(type);

    setForm(prevForm => ({
      ...prevForm,
      resource_type_id: type?.id || null,
    }));

    if (dirty) {
      touch();
    }
  };

  const onSelectImage = (value: string) => {
    setForm(prevForm => ({
      ...prevForm,
      image_path: value,
    }));

    if (dirty) {
      touch();
    }
  };

  const onSelectPdf = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      if (!file.name.endsWith('.pdf')) {
        setNotify({
          message: 'Please upload a pdf file',
          type: 'error',
        });
        return;
      }

      setPdfFileName(file.name);

      reader.onload = e => {
        const base64 = e.target?.result as string;
        setForm(prevForm => ({
          ...prevForm,
          file_path: base64,
        }));
      };

      reader.readAsDataURL(file);
    }

    if (dirty) {
      touch();
    }
  };

  const onSelectCheckbox = (
    type: 'is_day' | 'is_trend' | 'is_top' | 'is_recommended',
    checked: boolean,
  ) => {
    setForm(prevForm => ({
      ...prevForm,
      [type]: checked,
    }));
  };

  const handleSelectedYear = (date: Dayjs | null) => {
    const mappedDate = date?.format('YYYY-MM-DD');

    if (!mappedDate) {
      setForm(prevForm => ({
        ...prevForm,
        resource_update_date: null,
      }));
    }

    if (mappedDate && new Date(mappedDate) > new Date()) {
      setDateError('Date cannot be in the future');
      return;
    }

    if (mappedDate) {
      setForm(prevForm => ({
        ...prevForm,
        resource_update_date: date?.format('YYYY-MM-DD'),
      }));

      setDateError('');
    }

    if (dirty) {
      touch();
    }
  };

  const handleSelectedLanguage = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    setForm(prevForm => ({
      ...prevForm,
      doc_language: value as keyof typeof Langueages,
    }));

    if (dirty) {
      touch();
    }
  };

  useEffect(() => {
    if (companies) {
      setMappedCompanies(
        companies.map(company => ({
          id: company.id,
          name: company.name,
          value: company.id.toString(),
        })),
      );
    }
  }, [companies]);

  return (
    <FormModal
      loading={loading}
      title={`${isEdit ? 'Edit' : 'Create'} Resource`}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    >
      <FormControl sx={{ width: '100%' }}>
        <ImageLoader
          value={form.image_path || ''}
          accept=".jpg, .jpeg, .png"
          sx={{
            borderRadius: 2,
            width: 180,
            height: 320,
            margin: '0 auto 20px',
          }}
          {...{
            ...(isAdmin || isSuperAdmin ? {} : { aspectRatio: 9 / 16 }),
          }}
          onChange={onSelectImage}
        />

        {dirty && errors.image_path.dirty && (
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="error"
            sx={{
              mt: 1,
            }}
          >
            Cover image is required
          </Typography>
        )}
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          label="Resource name"
          placeholder="Resource name"
          value={form.name}
          name="name"
          onInput={onInput}
          disabled={loading}
          error={dirty && errors.name.dirty}
          helperText={dirty && mappingErrors(errors?.name?.message)}
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          label="Resource description"
          placeholder="Resource description"
          value={form.short_description}
          name="short_description"
          onInput={onInput}
          multiline
          rows={4}
          disabled={loading}
          error={dirty && errors.short_description.dirty}
          helperText={
            dirty && mappingErrors(errors?.short_description?.message)
          }
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <Autocomplete
          options={mappedCompanies}
          getOptionLabel={option => option.name}
          value={selectedCompany || null}
          disabled={loading}
          onChange={(_, value) => onSelectedCompany(value)}
          renderInput={params => (
            <DebounceInput
              {...params}
              label="Company"
              handleDebounce={onSearchCompanies}
              debounceTimeout={300}
              placeholder="Company"
              disabled={loading}
              error={dirty && errors.company_id.dirty}
            />
          )}
        />

        {dirty && errors.company_id.dirty && (
          <Typography
            variant="subtitle2"
            display="block"
            marginTop="4px"
            lineHeight={1.2}
            ml={1.75}
            color="error"
          >
            {mappingErrors(errors.company_id.message)}
          </Typography>
        )}
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <Autocomplete
          options={types}
          getOptionLabel={option => option.name}
          value={selectedTypes}
          disabled={loading}
          onChange={(_, value) => onSelectedType(value)}
          renderInput={params => (
            <DebounceInput
              {...params}
              label="Resource type"
              handleDebounce={onSearchTypes}
              debounceTimeout={300}
              disabled={loading}
              placeholder="Resource type"
              error={dirty && errors.resource_type_id.dirty}
            />
          )}
        />

        {dirty && (
          <Typography
            variant="subtitle2"
            display="block"
            marginTop="4px"
            lineHeight={1.2}
            color="error"
            ml={1.75}
          >
            {mappingErrors(errors.resource_type_id.message)}
          </Typography>
        )}
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-multiple-name-label-1">Language</InputLabel>

        <Select
          labelId="demo-multiple-name-label-1"
          value={form.doc_language}
          label="Language"
          disabled={loading}
          onChange={handleSelectedLanguage}
        >
          {Object.entries(Langueages).map(([language, value]) => (
            <MenuItem
              key={language}
              value={language as keyof typeof Langueages}
            >
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          label="Number of pages"
          placeholder="Number of pages"
          value={form.num_of_pages}
          type="number"
          inputMode="numeric"
          name="num_of_pages"
          disabled={loading}
          onInput={onInput}
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          label="Reading time (minutes)"
          placeholder="Reading time (minutes)"
          value={form.reading_time}
          name="reading_time"
          type="number"
          inputMode="numeric"
          disabled={loading}
          onInput={onInput}
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <DatePicker
              label="Resource creation date"
              sx={{ width: '100%' }}
              maxDate={dayjs(new Date())}
              value={
                form.resource_update_date
                  ? dayjs(form.resource_update_date)
                  : null
              }
              onChange={handleSelectedYear}
              disabled={loading}
              disableFuture
            />
          </DemoContainer>

          {dateError && (
            <Typography
              variant="subtitle2"
              display="block"
              marginTop="4px"
              lineHeight={1.2}
              color="error"
              ml={1.75}
            >
              {dateError}
            </Typography>
          )}
        </LocalizationProvider>
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          label="Number of downloads"
          placeholder="Number of downloads"
          value={form.random_generated_downloads}
          name="random_generated_downloads"
          type="number"
          disabled={loading}
          inputMode="numeric"
          onInput={onInput}
        />
      </FormControl>

      <FormControl sx={{ width: '100%' }}>
        <Button variant="contained" sx={{ maxWidth: '250px' }}>
          <label
            htmlFor="pdf_content"
            style={{
              display: 'block',
              width: '100%',
              height: '100%',
            }}
          >
            {form.file_path
              ? 'Change PDF' + ' ' + pdfFileName || resource?.name
              : 'Upload PDF'}
          </label>
        </Button>

        <input
          id="pdf_content"
          name="pdf_content"
          type="file"
          accept=".pdf"
          hidden
          disabled={loading}
          onChange={onSelectPdf}
        />

        {dirty && errors.file_path.dirty && (
          <Typography
            variant="subtitle2"
            display="block"
            marginTop="4px"
            lineHeight={1.2}
            ml={1.75}
            color="error"
          >
            {mappingErrors(errors.file_path.message)}
          </Typography>
        )}
      </FormControl>

      <Box>
        <FormControlLabel
          control={<Checkbox value={form.is_day} checked={form.is_day} />}
          label="Is a day resource"
          onChange={(_, checked) => onSelectCheckbox('is_day', checked)}
        />

        <FormControlLabel
          control={
            <Checkbox
              value={form.is_recommended}
              checked={form.is_recommended}
              disabled={loading}
            />
          }
          label="Is a recommended resource"
          onChange={(_, checked) => onSelectCheckbox('is_recommended', checked)}
        />

        <FormControlLabel
          control={
            <Checkbox
              value={form.is_top}
              checked={form.is_top}
              disabled={loading}
            />
          }
          label="Is a top resource"
          onChange={(_, checked) => onSelectCheckbox('is_top', checked)}
        />

        <FormControlLabel
          control={
            <Checkbox
              value={form.is_trend}
              checked={form.is_trend}
              disabled={loading}
            />
          }
          label="Is a trend resource"
          onChange={(_, checked) => onSelectCheckbox('is_trend', checked)}
        />
      </Box>
    </FormModal>
  );
};

export default ResourceForm;
