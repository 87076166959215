import { useState, useEffect, useMemo, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Typography } from '@mui/material';

import ContentLayout from '@/shared/layouts/ContentLayout';

import ResourcesDataApi from './api/resources.api';

import {
  ResourcesItemType,
  GetParamsResourceType,
  ResourceFormType,
  ResourceTypesType,
} from './types';

import ResourcesList from './component/ResourcesList';
import ResourceForm from './forms/resource.form';
import ResourceImagesForm from './forms/resource-images.form';
import ResourcesFilters from './component/ResourcesFilters';

import { useNotify } from '@/shared/context/notifyContext';
import useQueryParams from '@/shared/hooks/useQueryParams.hook';

import {
  CompanyItemType,
  GetParamsCompanyType,
} from '@/shared/api/compnaies/compnaies.api.types';

import { TTypeItem, TTypesGetParams } from '@/shared/api/types/types.api.types';

import { Tid } from '@/shared/types/promises.types';

import { fetchCompaniesList } from '@/shared/api/compnaies/compnaies.api';
// import KeywordsDataApi from '@/shared/api/keywords/keywords.api';
import { GetTypesItems } from '@/shared/api/types/types.api';

import { OrderType } from '../companies/types';
import checkErrorMessageHelperSnippet from '@/shared/helpers/checkErrorMessage.helper';
import base64ToFormData from '@/shared/helpers/base64ToFormData.helper';

const RsourcesContainer = () => {
  const { setNotify } = useNotify();

  const [epmtyRequests, setEmptyRequests] = useState<number>(0);
  const [step, setStep] = useState<number>(1);

  const [queries, setQueries] = useQueryParams<GetParamsResourceType>({});

  const [offset] = useState<string>('10');
  const [pages, setPages] = useState<string>('1');

  const [companiesParams, setCompaniesParams] = useState<GetParamsCompanyType>({
    page: '1',
    pageCount: '100',
    query: '',
  });

  const [typesParams, setTypesParams] = useState<TTypesGetParams>({
    page: '1',
    pageCount: '100',
    query: '',
  });

  const [resources, setResources] = useState<ResourcesItemType[]>([]);
  const [totalResources, setTotalResources] = useState<number>(0);
  const [companies, setCompanies] = useState<CompanyItemType[]>([]);
  const [types, setTypes] = useState<TTypeItem[]>([]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingResourcesForm, setLoadingResourcesForm] =
    useState<boolean>(false);

  const [selectedResource, setSelectedResource] =
    useState<ResourcesItemType | null>(null);

  const onClearModalState = () => {
    setRemoveModal(false);
    setSelectedResource(null);
    setOpenModal(false);
  };

  const onSetSelectedResource = (id: Tid) => {
    const resource = resources.find(resource => resource.id === id) || null;

    setSelectedResource(resource);
  };

  const handleClose = () => {
    onClearModalState();
    setLoadingResourcesForm(false);
    setStep(1);
  };

  const handleOpenCreateModal = () => {
    setOpenModal(true);
    setSelectedResource(null);
  };

  const handleOpenRemoveModal = (id: Tid) => {
    setRemoveModal(true);
    setOpenModal(true);
    onSetSelectedResource(id);
  };

  const handleOpenEditModal = (id: Tid) => {
    setOpenModal(true);

    onSetSelectedResource(id);
  };

  const handleOpenEditPreviewsModal = (id: Tid) => {
    setOpenModal(true);
    setStep(2);
    onSetSelectedResource(id);
  };

  const fetchResources = useCallback(
    async (controller?: AbortSignal) => {
      setLoading(true);

      try {
        const { data, total_resources: total_items } =
          await ResourcesDataApi.getResources(
            {
              pageCount: offset,
              page: '1',
              ...queries,
            },
            controller,
          );

        setTotalResources(total_items || 0);

        const total = total_items ? total_items : 0;

        setPages(String(Math.ceil(total / +offset)));

        setResources(data);

        setEmptyRequests(prev => prev + 1);
      } catch (error) {
        console.error(error);

        // if ((error as Error).name !== 'AbortError') {
        //   checkErrorMessageHelperSnippet(error as Error, setNotify);
        // }
      } finally {
        setLoading(false);
      }
    },
    [queries],
  );

  const fetchCompanies = async () => {
    try {
      const { data } = await fetchCompaniesList(companiesParams);

      setCompanies([...companies, ...data]);
    } catch (error) {
      setNotify({ message: 'Error while fetching companies', type: 'error' });
    }
  };

  const fetchTypes = async () => {
    try {
      const { data } = await GetTypesItems(typesParams);

      setTypes([...types, ...data]);
    } catch (error) {
      setNotify({ message: 'Error while fetching types', type: 'error' });
    }
  };

  const onSearchResources = (query: string) => {
    if (query.length === 0 && epmtyRequests !== 1) {
      setQueries({ ...queries, query: undefined, page: '1' });
      return;
    }

    if (query.length >= 3) {
      setQueries({ ...queries, query, page: '1' });
      return;
    }
  };

  const onSearchCompanies = (query: string) => {
    setCompanies([]);

    setCompaniesParams({ ...companiesParams, query });
  };

  const onSearchTypes = (query: string) => {
    setTypes([]);

    setTypesParams({ ...typesParams, query });
  };

  const onSelectResourceType = (name: ResourceTypesType[]) => {
    setQueries({
      ...queries,
      isDay: name.includes('isDay'),
      isRecommended: name.includes('isRecommended'),
      isTop: name.includes('isTop'),
      isTrend: name.includes('isTrend'),
      page: '1',
    });
  };

  const onSelectedType = (value: TTypeItem[]) => {
    setQueries({
      ...queries,
      resourceType: value.map(item => item.value).join(',') || undefined,
      page: '1',
    });
  };

  const onChangePage = (page: string) => {
    setQueries({ ...queries, page });
  };

  const onSortResources = (sort: string) => {
    const newSort: OrderType = queries.order_by?.includes(sort)
      ? queries.order_by.includes('-')
        ? (sort as OrderType)
        : (`-${sort}` as OrderType)
      : (sort as OrderType);

    setQueries({ ...queries, order_by: newSort, page: '1' });
  };

  const onCreateResource = async (form: ResourceFormType) => {
    setLoadingResourcesForm(true);

    try {
      const response = await ResourcesDataApi.createResource(form);

      setNotify({ message: 'Resource created successfully', type: 'success' });

      // onClearModalState();
      setStep(2);
      setRemoveModal(false);
      setSelectedResource(response);

      fetchResources();
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingResourcesForm(false);
    }
  };

  const onEditResource = async (form: ResourceFormType) => {
    if (!selectedResource) return;

    setLoadingResourcesForm(true);

    try {
      await ResourcesDataApi.updateResource(selectedResource.id, form);

      setNotify({ message: 'Resource updated successfully', type: 'success' });

      onClearModalState();
      fetchResources();
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingResourcesForm(false);
    }
  };

  const onRemoveResource = async () => {
    if (!selectedResource) return;

    try {
      await ResourcesDataApi.deleteResource(selectedResource.id);

      setNotify({ message: 'Resource removed successfully', type: 'success' });

      onClearModalState();
      fetchResources();
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    }
  };

  const onSubmitPreviews = async (previews: string[], deleted: number[]) => {
    setLoadingResourcesForm(true);

    try {
      if (deleted) {
        const deletePromises = deleted.map(id =>
          ResourcesDataApi.deletePreview(id),
        );

        await Promise.all(deletePromises);
      }

      if (selectedResource) {
        const id = selectedResource.id;

        const promises = previews.map((preview, index) => {
          const formData = new FormData();

          formData.append('pdf_id', String(id));
          formData.append(
            'pdf_image',
            base64ToFormData(
              preview,
              `preview-${new Date().getTime() + index}`,
            ),
          );

          return ResourcesDataApi.savePreview(formData);
        });

        await Promise.all(promises);

        onClearModalState();
        fetchResources();
      }

      setNotify({
        message: 'Previews updated successfully',
        type: 'success',
      });
    } catch (error) {
      checkErrorMessageHelperSnippet(error as Error, setNotify);
    } finally {
      setLoadingResourcesForm(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    fetchResources(controller.signal);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries]);

  useEffect(() => {
    fetchCompanies();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesParams]);

  useEffect(() => {
    fetchTypes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typesParams]);

  // useEffect(() => {
  //   fetchKeywordsList();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [keywordsParams]);

  const memoizedRsources = useMemo(() => resources, [resources]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>INKHUB Admin Page | Resources</title>
      </Helmet>
      <ContentLayout
        title={
          <>
            Resources
            <Typography variant="overline" display="block" marginTop={1.5}>
              Total resources: {totalResources} items
            </Typography>
          </>
        }
        buttonTitle="Create new Resource"
        removeTitleModal="Remove Resource"
        removeTitleMessage="Are you sure you want to remove this resource?"
        loading={loading}
        formLoading={loadingResourcesForm}
        openModal={openModal}
        isRemoveModal={removeModal}
        selectedItem={selectedResource}
        onRemoveItem={onRemoveResource}
        onCloseModal={handleClose}
        onCreate={handleOpenCreateModal}
        form={
          step === 2 ? (
            <ResourceImagesForm
              loading={loadingResourcesForm}
              resource={selectedResource}
              onCancel={handleClose}
              onSumbit={onSubmitPreviews}
            />
          ) : (
            <ResourceForm
              loading={loadingResourcesForm}
              resource={selectedResource}
              companies={companies}
              types={types}
              onCancel={handleClose}
              onCreate={onCreateResource}
              onUpdate={onEditResource}
              onSearchCompanies={onSearchCompanies}
              onSearchTypes={onSearchTypes}
            />
          )
        }
      >
        <ResourcesFilters
          queries={queries}
          types={types}
          onSearchResources={onSearchResources}
          onSelectResourceType={onSelectResourceType}
          onSearchTypes={onSearchTypes}
          onSelectedType={onSelectedType}
        />

        <ResourcesList
          resources={memoizedRsources}
          params={queries}
          pages={+pages}
          loading={loading}
          onDelete={handleOpenRemoveModal}
          onEdit={handleOpenEditModal}
          onSort={onSortResources}
          onChangePage={onChangePage}
          onEditPreviews={handleOpenEditPreviewsModal}
        />
      </ContentLayout>
    </>
  );
};

export default RsourcesContainer;
